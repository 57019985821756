<template>
  <div
    id="file-list-item"
    :class="{ 'is-selected': isSelected, 'mini-mode': miniMode }"
    v-on="$listeners"
  >
    <img :src="showThumbnail" alt="thumbnail" class="thumbnail" />
    <div v-if="false" class="avatar q-ml-md">
      <FileIcon :mime-type="type" />
    </div>

    <div class="q-ml-md">
      <div class="title">
        <FileIcon :mime-type="type" class="mini-avatar q-mr-sm" />
        {{ file.name }}
        <slot name="info" />
      </div>

      <div class="meta">
        <!-- created at -->

        <div>{{ createdAt }}</div>

        <!-- ... -->

        <!-- created by -->

        <template v-if="file.createdBy && !miniMode">
          <BaseIcon name="mdi-circle-small" />
          <div>{{ file.createdBy }}</div>
        </template>

        <!-- ... -->

        <!-- size -->

        <template v-if="file.size">
          <BaseIcon name="mdi-circle-small" />
          <div>{{ size }}</div>
        </template>

        <!-- ... -->
      </div>
    </div>
  </div>
</template>

<script>
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";

export default {
  name: "FileListItem",

  components: { FileIcon },

  props: {
    file: {
      type: Object,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    miniMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    type() {
      return this.file.name.split(".").reverse()[0].slice(0, 4);
    },

    createdAt() {
      return this.$day.format(this.file.createdAt);
    },

    size() {
      return formatBytes(this.file.size);
    },

    showThumbnail() {
      return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.file.repositoryId}/${this.file.id}/1`;
    },
  },
};
</script>

<style lang="scss" scoped>
#file-list-item {
  display: flex;
  align-items: center;
  padding: 16px;
  position: relative;

  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--divider-color);
  }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }
}

.theme-light {
  #file-list-item {
    &.is-selected {
      background-color: $gray-1;
    }
  }
}

.theme-dark {
  #file-list-item {
    &.is-selected {
      background-color: $dark-1;
    }
  }
}
</style>
