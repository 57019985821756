<template>
  <div id="item-overview">
    <table>
      <tbody>
        <tr v-for="info in overview" :key="info.id">
          <td class="label">{{ info.label }}</td>

          <td class="value">
            <slot :name="info.name" :value="info.value">
              <template
                v-if="info.name === 'options' && info.label === 'MenuOptions'"
              >
                <MenuOptionsDetails :options="info.value" />
              </template>
              <template v-else>
                {{ info.value || "-" }}
              </template>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import MenuOptionsDetails from "@/views/profile/views/profile-browse-and-trash/components/MenuOptionsDetails";
export default {
  name: "ItemOverview",

  components: { MenuOptionsDetails },

  props: {
    overview: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#item-overview {
  padding: 16px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td {
      vertical-align: top;
      padding: 16px 8px;
    }
  }

  .label {
    color: var(--icon-color);
    width: 200px;
  }

  .value {
    font-weight: 500;
  }
}

.theme-light {
  #item-overview {
    tr:nth-child(even) {
      background-color: rgba($color: $gray-6, $alpha: 0.06);
    }
  }
}

.theme-dark {
  #item-overview {
    tr:nth-child(even) {
      background-color: rgba($color: $gray-5, $alpha: 0.06);
    }
  }
}
</style>
