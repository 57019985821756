const DataType = {
  SHORT_TEXT: "SHORT_TEXT",
  LONG_TEXT: "LONG_TEXT",
  NUMBER: "NUMBER",
  BOOLEAN: "BOOLEAN",
  DATE: "DATE",
  TIME: "TIME",
  DATE_TIME: "DATE_TIME",
  SINGLE_SELECT: "SINGLE_SELECT",
  // MULTI_SELECT: "MULTI_SELECT",
  TABLE: "TABLE",
  BARCODE: "BARCODE",
  OMR: "OMR",
  // CALCULATED: "CALCULATED",
  AUTO_GENERATED: "AUTO_GENERATED",
};

Object.freeze(DataType);

export default DataType;
