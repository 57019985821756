<template>
  <div id="async-select-field" class="cursor-pointer">
    <FormFieldLabel
      v-if="label"
      :label="label"
      :is-mandatory="isMandatory"
      :tooltip="tooltip"
    />

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="_isFocused"
      :is-clearable="_isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      action-icon="eva-chevron-down"
      @click="showOptionPicker"
      @clear="handleClear"
    >
      <template #default>
        <!-- field -->

        <div class="async-select">
          <div v-for="(option, idx) in value" :key="option" class="chip">
            <div class="q-mr-xs">{{ option }}</div>

            <BaseIcon name="eva-close" @click.stop="removeOption(idx)" />
          </div>

          <div class="col search-query">
            <input
              ref="searchQuery"
              type="text"
              :value="searchQuery"
              :placeholder="_placeholder"
              @focus="isFocused = true"
              @blur="isFocused = false"
              @input="debouncedInput"
              @keypress.enter="handleKeypress"
            />
          </div>
        </div>

        <!-- ... -->

        <!-- option picker -->

        <q-menu
          v-model="optionPicker"
          :target="$refs.wrapper"
          fit
          no-focus
          no-refocus
          no-parent-event
          transition-show="scale"
          transition-hide="scale"
        >
          <OptionPicker
            is-multiple
            :value="value"
            :options="_options"
            :new-option="newOption"
            @select="handleSelect"
            @loadMore="getOptions"
          />
        </q-menu>

        <!-- ... -->
      </template>

      <template #action>
        <q-spinner
          v-if="isLoading"
          color="secondary"
          size="20px"
          class="q-mr-sm"
        />
      </template>
    </FormFieldWrapper>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import OptionPicker from "./OptionsPicker";
import { axiosCrypto } from "@/api/axios.js";
import { debounce, lowerCase, cloneDeep, isEqual } from "lodash-es";

export default {
  name: "AsyncMultiSelectField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    OptionPicker,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    error: {
      type: String,
      default: "",
    },

    newOption: {
      type: Boolean,
      default: false,
    },

    apiPath: {
      type: String,
      default: "",
    },

    columnName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFocused: false,
      optionPicker: false,
      searchQuery: "",
      options: [],
      isLoading: false,
      optionsCount: 0,
      optionsClone: [],
    };
  },

  computed: {
    _placeholder() {
      if (this.value.length) {
        return;
      }

      return this.placeholder || "Select";
    },

    _isFocused() {
      return this.isFocused || this.optionPicker;
    },

    _isClearable() {
      return this.isClearable && !!this.value.length;
    },

    _options() {
      if (!this.searchQuery) {
        return this.optionsClone;
      }
      return this.optionsClone.filter((option) =>
        lowerCase(option).includes(lowerCase(this.searchQuery))
      );
    },
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEqual(this.options, this.optionsClone)) {
          this.optionsClone = cloneDeep(this.options);
        }
      },
    },

    optionPicker() {
      if (!this.optionPicker) {
        this.searchQuery = "";
      }
    },

    // searchQuery: {
    //   immediate: true,
    //   handler: "getOptions",
    // },

    columnName() {
      if (this.columnName) {
        this.options = [];
        this.getOptions();
      }
    },
  },

  created() {
    this.getOptions();
  },

  methods: {
    showOptionPicker() {
      this.optionPicker = true;
    },

    handleChange(e) {
      this.searchQuery = e.target.value;
    },

    handleSelect(option) {
      const selectedOptions = [...this.value];

      const optionIdx = selectedOptions.findIndex(
        (_option) => _option === option
      );

      if (optionIdx === -1) {
        selectedOptions.push(option);
      } else {
        selectedOptions.splice(optionIdx, 1);
      }

      this.$emit("input", selectedOptions);
      this.searchQuery = "";
    },

    removeOption(optionIdx) {
      const selectedOptions = [...this.value];
      selectedOptions.splice(optionIdx, 1);
      this.$emit("input", selectedOptions);
    },

    handleKeypress() {
      if (!this.searchQuery) {
        return;
      }

      if (this.newOption) {
        this.handleSelect(this.searchQuery);
        this.$refs.searchQuery.blur();
      }
    },

    handleClear() {
      this.$emit("input", []);
    },

    debouncedInput: debounce(function (e) {
      this.searchQuery = e.target.value;
    }, 500),

    async getOptions() {
      try {
        let criteriaName = "";
        if (this.label) {
          criteriaName = this.label;
        } else if (typeof this.columnName === "object") {
          criteriaName = this.columnName[0];
        } else {
          criteriaName = this.columnName;
        }
        const response = await axiosCrypto.post(
          this.apiPath,
          JSON.stringify({
            column: criteriaName,
            keyword: this.searchQuery,
            rowFrom: this.options.length || 0,
            rowCount: this.options.length + 10,
          })
        );
        const { status, data } = response;

        if (status !== 200) {
          throw response;
        }

        const options = JSON.parse(data);
        this.options.push(...options);
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    loadMoreOptions() {},
  },
};
</script>

<style lang="scss" scoped>
#async-select-field {
  .async-select {
    min-height: 46px;
    padding: 0px 8px 8px;
    display: flex;
    flex-wrap: wrap;

    .chip {
      font-weight: 500;
      background-color: var(--component-bg-color-inferior);
      padding: 4px 8px;
      border-radius: 4px;
      margin-top: 8px;
      margin-right: 8px;
      display: flex;
      align-items: center;
    }

    .search-query {
      margin-top: 8px;
      margin-right: 8px;

      input {
        min-width: 64px;
        height: 100%;
        padding: 0px 4px;
      }
    }
  }
}
</style>
