<template>
  <Confirm
    :value="value"
    icon="eva-trash-2-outline"
    icon-color="orange"
    @input="handleInput"
  >
    <template #title>Restore Repository?</template>

    <template #default>
      <div class="q-py-md">
        <p>
          Are you sure you want to restore the repository
          <b>"{{ repository.name }}"</b>. If you restore the repository, you
          will be able to use the repository.
        </p>

        <p>
          You can always delete the restored repository from the browse page.
        </p>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="orange"
        @click="handleNo"
      />
      <BaseButton label="yes" color="orange" @click="handleYes" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmRestoreRepository",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    repository: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("restore", { isDeleted: false });
    },
  },
};
</script>

<style lang="scss" scoped></style>
