<template>
  <div id="menu-options">
    <div class="options-wrapper">
      <div class="row q-col-gutter-md">
        <template v-for="option in options">
          <template v-for="(value, key) in option">
            <div
              v-if="key !== 'Menu' && key !== 'dashboard'"
              :key="key"
              :tabindex="0"
              class="option col-4 is-selected"
            >
              <BaseIcon
                v-if="key !== 'Menu' && key !== 'dashboard'"
                :key="key"
                :name="
                  isSelected(value)
                    ? 'eva-checkmark-outline'
                    : 'eva-close-outline'
                "
                :color="isSelected(value) ? 'positive' : 'negative'"
                class="icon"
              />
              <div>
                <div v-if="key !== 'Menu'" class="label">
                  {{ key }}
                </div>
              </div>
            </div>
          </template>
          <div
            v-for="(menu, key1) in option.Menu"
            :key="menu[key1]"
            :tabindex="0"
            class="option1 col-12 is-selected"
          >
            <BaseIcon
              :key="key1"
              :name="
                isSelected(menu) ? 'eva-checkmark-outline' : 'eva-close-outline'
              "
              :color="isSelected(menu) ? 'positive' : 'negative'"
              class="icon"
            />
            <div>
              <div class="label">{{ key1 }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuOptionsDetails",

  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    isSelected(option) {
      return option;
    },
  },
};
</script>

<style lang="scss" scoped>
#menu-options {
  .options-wrapper {
    text-transform: capitalize;
  }

  .option {
    display: flex;
    align-items: center;
    outline: none;
    margin-top: 5px;

    .label {
      color: var(--icon-color);
    }

    // .icon {
    //   color: var(--icon-color-inverted);
    //   // color: var(--body-text-color);
    // }

    &.is-focused,
    &.is-selected {
      .icon {
        color: var(--secondary);
      }
    }

    &.is-selected {
      .label {
        font-weight: 500;
        color: var(--body-text-color);
      }
    }
  }

  .option1 {
    display: flex;
    align-items: center;
    outline: none;
    margin-left: 14px;
    // border-bottom: 1px solid var(--divider-color);

    .label {
      color: var(--icon-color);
    }

    // .icon {
    //   color: var(--icon-color-inverted);
    // }

    &.is-focused,
    &.is-selected {
      .icon {
        color: var(--secondary);
      }
    }

    &.is-selected {
      .label {
        font-weight: 500;
        color: var(--body-text-color);
      }
    }
  }
}
</style>
