<template>
  <Confirm
    :value="value"
    icon="mdi-lock-outline"
    icon-color="primary"
    width="450px"
    @input="closeModal"
  >
    <!-- title -->

    <template #title>Password</template>

    <!-- ... -->

    <template #default>
      <ValidationObserver ref="form">
        <!-- field -->

        <ValidationProvider
          v-slot="{ errors }"
          name="field"
          :rules="{ required: true, password: true }"
        >
          <PasswordField
            v-model="password"
            label="Enter Password"
            is-mandatory
            :error="errors[0]"
            class="q-my-lg"
            @enter="validatePassword"
          />
        </ValidationProvider>

        <!-- ... -->
      </ValidationObserver>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" @click="validatePassword" />
    </template>

    <!-- ... -->
  </Confirm>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Confirm from "@/components/common/popup/Confirm.vue";
import { menu } from "@/api/factory.js";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";

export default {
  name: "FilePassword",

  components: {
    Confirm,
    ValidationObserver,
    ValidationProvider,
    PasswordField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    repositoryId: {
      type: [Number, String],
      required: true,
    },

    itemId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      password: "",
    };
  },

  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },

    async validatePassword() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await menu.validatePassword({
        repositoryId: this.repositoryId,
        itemId: this.itemId,
        password: this.password,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload);
      if (payload.status === 200) {
        // this.$alert.success(`password set successfully`);
        this.$emit("close", false);
      } else if (payload.status === 404) {
        this.$alert.success(`password is wrong`);
      }
      this.password = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
