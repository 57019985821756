<template>
  <div id="action" v-on="$listeners">
    <BaseIcon :name="icon" inherit-color />

    <div class="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: "Action",

  props: {
    icon: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
#action {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-color-inverted);
  padding: 16px;
  transition: all 0.25s ease;

  &:hover {
    cursor: pointer;
    color: var(--secondary);
    background-color: var(--hover-bg-color);
  }

  .label {
    @extend .text-sm;
    margin-left: 8px;
  }
}
</style>
