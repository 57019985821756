<template>
  <Confirm
    :value="value"
    icon="eva-trash-2-outline"
    icon-color="orange"
    @input="handleInput"
  >
    <template #title>
      Restore{{ entityType === "FOLDER" ? "Folder" : "File" }}?
    </template>

    <template #default>
      <div class="q-py-md">
        Are you sure you want to restore the
        {{ entityType === "FOLDER" ? "Folder" : "File" }}
        <span class="font-bold">"{{ entity.name }}" ?</span>
        <span v-if="entityType === 'FOLDER'">
          If you restore this folder, all the files and folders inside it will
          also be restored.
        </span>

        <p>
          You can always delete the restored
          {{ entityType === "FOLDER" ? "Folder" : "File" }} from the browse
          page.
        </p>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="orange"
        @click="handleNo"
      />
      <BaseButton label="yes" color="orange" @click="handleYes" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmRestoreEntity",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    entity: {
      type: Object,
      required: true,
    },

    entityType: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("restore", { isDeleted: false });
    },
  },
};
</script>

<style lang="scss" scoped></style>
