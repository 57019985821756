<template>
  <ItemGrid
    :columns="columns"
    :items="items"
    :mode="mode"
    :security="security"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
    @secure="handleSecure"
  >
    <template #fieldsType="{ column, value }">
      <div class="row q-px-md q-py-sm">
        <div class="label">{{ column }}</div>

        <q-space />

        <div id="repository-type" :class="{ dynamic: value === 'Dynamic' }">
          <BaseIcon name="mdi-circle" size="8px" inherit-color />

          <div class="q-ml-sm">{{ value }}</div>
        </div>
      </div>
    </template>

    <template #files="{ column, value }">
      <div class="row q-px-md q-py-sm">
        <div class="label">{{ column }}</div>

        <q-space />

        <div>
          {{ appendZeroIfNeeded(value) }}
        </div>
      </div>
    </template>
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";

export default {
  name: "RepositoryGrid",

  components: { ItemGrid },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },

    security: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    appendZeroIfNeeded(count) {
      if (count === 0) {
        return 0;
      }
      return count < 10 ? `0${count}` : count;
    },

    handleSelect(repositoryId) {
      this.$emit("select", repositoryId);
    },

    handleEdit(repositoryId) {
      this.$emit("edit", repositoryId);
    },

    handleDelete(repositoryId) {
      this.$emit("delete", repositoryId);
    },

    handleRestore(repositoryId) {
      this.$emit("restore", repositoryId);
    },

    handleMore(repositoryId) {
      this.$emit("more", repositoryId);
    },

    handleSecure(repositoryId) {
      this.$emit("secure", repositoryId);
    },
  },
};
</script>

<style lang="scss" scoped>
#repository-type {
  display: flex;
  align-items: center;
  color: var(--green);

  &.dynamic {
    color: var(--orange);
  }
}
</style>
