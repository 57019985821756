<template>
  <Confirm
    :value="value"
    icon="eva-trash-2-outline"
    icon-color="red"
    @input="handleInput"
  >
    <template #title>
      Delete {{ entityType === "FOLDER" ? "Folder" : "File" }}?
    </template>

    <template #default>
      <div class="q-py-md">
        <template v-if="entityList.length === 1">
          <p>
            Are you sure you want to delete the File
            <span>{{ entityType === "FOLDER" ? "Folder" : "File" }}</span>
            <span class="font-bold">"{{ entityList[0].name }}" ?</span>
            <span v-if="entityType === 'FOLDER'">
              If you delete this folder, all the files and folders inside it
              will also be deleted.
            </span>
          </p>
        </template>
        <template v-else-if="entityList.length > 1">
          <p>
            Are you sure you want to delete the
            <span>{{ entityType === "FOLDER" ? "Folder" : "File" }}</span>
            <span class="font-bold">"{{ entityList.length }}" Files ?</span>
            <span v-if="entityType === 'FOLDER'">
              If you delete this folder, all the files and folders inside it
              will also be deleted.
            </span>
          </p>
        </template>
        <template v-else>
          <p>
            Are you sure you want to delete the
            <span>{{ entityType === "FOLDER" ? "Folder" : "File" }}</span>
            <span class="font-bold">"{{ entity.name }}" ?</span>
            <span v-if="entityType === 'FOLDER'">
              If you delete this folder, all the files and folders inside it
              will also be deleted.
            </span>
          </p>
        </template>

        <p>
          You can always restore the deleted
          {{ entityType === "FOLDER" ? "Folder" : "File" }} from the trash page.
        </p>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="red"
        @click="handleNo"
      />
      <BaseButton label="yes" color="red" @click="handleYes" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmDeleteEntity",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    entity: {
      type: Object,
      default: () => {},
    },

    entityType: {
      type: String,
      required: true,
    },

    entityList: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("delete", { isDeleted: true });
    },
  },
};
</script>

<style lang="scss" scoped></style>
