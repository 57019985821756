<template>
  <ItemDetails :value="value" :overview="overview" @input="handleInput">
    <template #title>Entity Details</template>
  </ItemDetails>
</template>

<script>
import ItemDetails from "@/components/common/display/item-details/ItemDetails.vue";

export default {
  name: "EntityDetails",

  components: { ItemDetails },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      required: true,
    },

    entity: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showAllEntityUsers: false,
    };
  },

  computed: {
    overview() {
      return this.columns.map((column) => ({
        id: column.id,
        name: column.name,
        label: column.label,
        value: this.entity[column.name],
      }));
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
