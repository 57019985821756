<template>
  <component
    :is="component"
    :mode="mode"
    :full-file-sheet="fullFileSheet"
    :hide-file-sheet="hideFileSheet"
    @minimumFileSheet="minimumFileSheet"
  />
</template>

<script>
import { isEmpty } from "lodash-es";
import RepositoriesBrowseAndTrash from "./repositories-browse-and-trash/RepositoriesBrowseAndTrash.vue";
import RepositoryBrowseAndTrash from "./repository-browse-and-trash/RepositoryBrowseAndTrash.vue";

export default {
  name: "RepositoriesBrowseAndTrashWrapper",

  components: { RepositoriesBrowseAndTrash, RepositoryBrowseAndTrash },

  props: {
    mode: {
      type: String,
      required: true,
    },

    fullFileSheet: {
      type: Object,
      default: () => {},
    },

    hideFileSheet: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    component() {
      return isEmpty(this.$route.query)
        ? "RepositoriesBrowseAndTrash"
        : "RepositoryBrowseAndTrash";
    },
  },

  methods: {
    minimumFileSheet(file) {
      // console.log(file);
      // this.minimumFileSheet = file;
      this.$emit("minimumFileSheet", file);
    },
  },
};
</script>

<style lang="scss" scoped></style>
