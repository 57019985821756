<template>
  <div v-if="showChips" id="item-action-chips">
    <!-- sort by -->

    <div v-if="sortBy.criteria" class="chip sort-by">
      <div class="label">Sort by: {{ getColumnLabel(sortBy.criteria) }}</div>

      <BaseIcon
        :name="
          sortBy.order === 'ASC' ? 'mdi-sort-ascending' : 'mdi-sort-descending'
        "
        inherit-color
        class="q-mr-md"
      />

      <BaseIcon
        name="mdi-close-circle"
        inherit-color
        class="cursor-pointer"
        @click="clearSortBy"
      />
    </div>

    <!-- ... -->

    <!-- group by -->

    <div v-if="groupBy" class="chip group-by">
      <div class="label">Group by: {{ getColumnLabel(groupBy) }}</div>

      <BaseIcon
        name="mdi-close-circle"
        inherit-color
        class="cursor-pointer"
        @click="clearGroupBy"
      />
    </div>

    <!-- ... -->

    <!-- filter by -->
    <!-- <template v-if="filterBy.length"> -->
    <!-- <div
      v-for="(filter, index) in filterBy[0].filters"
      
      :key="index"
      class="chip filter-by"
    >
      <div class="label">
        <BaseIcon name="eva-funnel-outline" inherit-color class="q-mr-xs" />
        {{ filter.criteria }} {{ filter.condition }}
        {{ filter.value }}
      </div>

      <BaseIcon
        name="mdi-close-circle"
        inherit-color
        class="cursor-pointer"
        @click="clearFilterBy(index)"
      />
    </div> -->

    <div v-if="contentSearch.contentSearchValue" class="chip filter-by">
      <div class="label">
        <BaseIcon name="eva-funnel-outline" inherit-color class="q-mr-xs" />
        SearchBy: {{ contentSearch.contentSearchValue }}
      </div>

      <BaseIcon
        name="mdi-close-circle"
        inherit-color
        class="cursor-pointer"
        @click="$emit('clearContentSearch')"
      />
    </div>
    <BaseScrollbar>
      <div v-if="filterBy.length" style="display: flex" class="q-mb-md">
        <div v-for="(filters, index) in filterBy" :key="index" class="col-auto">
          <div
            v-if="filters.filters.length"
            :class="{ 'filterBy-length': filterBy.length > 1 }"
          >
            <div
              v-for="(filter, idx) in filters.filters"
              :key="idx"
              class="chip filter-by"
            >
              <div class="label">
                <BaseIcon
                  name="eva-funnel-outline"
                  inherit-color
                  class="q-mr-xs"
                />
                {{ getColumnLabel(filter.criteria) }} {{ filter.condition }}
                {{ filter.value }}
              </div>

              <BaseIcon
                name="mdi-close-circle"
                inherit-color
                class="cursor-pointer"
                @click="clearFilterBy(index, idx)"
              />
            </div>
          </div>
        </div>
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
export default {
  name: "ItemActionChips",

  props: {
    columns: {
      type: Array,
      required: true,
    },

    sortBy: {
      type: Object,
      required: true,
    },

    groupBy: {
      type: String,
      required: true,
    },

    filterBy: {
      type: Array,
      required: true,
    },

    contentSearch: {
      type: Object,
      default: () => {
        return { contentSearchValue: "", fuzzy: 0, searchType: 1 };
      },
    },

    simpleSearchApplyed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    showChips() {
      if (this.sortBy.criteria) {
        return true;
      }

      if (this.groupBy) {
        return true;
      }

      if (this.simpleSearchApplyed) {
        return false;
      }

      if (this.filterBy.length) {
        return true;
      }

      if (this.contentSearch.contentSearchValue) {
        return true;
      }

      return false;
    },
  },

  methods: {
    getColumnLabel(columnName) {
      return this.columns.find((column) => column.name === columnName)?.label;
    },

    clearSortBy() {
      this.$emit("clearSortBy");
    },

    clearGroupBy() {
      this.$emit("clearGroupBy");
    },

    clearFilterBy(id, index) {
      this.$emit("clearFilterBy", id, index);
    },
  },
};
</script>

<style lang="scss" scoped>
#item-action-chips {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;

  .chip {
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: white;

    .label {
      font-weight: 500;
      margin-right: 8px;
    }
  }

  .sort-by {
    background-color: var(--indigo);
  }

  .group-by {
    background-color: var(--deep-orange);
  }

  .filter-by {
    background-color: var(--secondary);
  }

  .filterBy-length {
    background-color: rgba(0, 188, 212, 0.15);
    border-radius: 4px;
    padding: 8px;
    font-weight: 500;
    margin-right: 10px;
    text-transform: capitalize;
    // display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    user-select: none;
    height: 100%;
  }
}
</style>
