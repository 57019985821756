<template>
  <div id="history">
    <template v-if="changes.length">
      <div v-for="item in changes" :key="item.id" class="entry">
        <div class="header">
          {{ item.changes.length }}
          {{ item.changes.length > 1 ? "Changes" : "Change" }}
        </div>

        <div class="content">
          <div class="changes-meta">
            <div>{{ item.changedBy }}</div>
            <BaseIcon name="mdi-circle-small" class="q-mx-xs" />
            <div>{{ item.changedAt }}</div>
          </div>

          <div
            v-for="(change, idx) in item.changes"
            :key="idx + 1"
            class="change"
          >
            <BaseIcon name="mdi-circle-small" class="q-mr-sm" />

            <div v-if="change.column === 'isDeleted'">
              <span v-if="Number(change.newValue)" class="font-semibold">
                "Deleted"
              </span>
              <span v-else class="font-semibold">"Restored"</span>
              the item
            </div>

            <div v-else-if="change.oldValue">
              Modified {{ change.column }} from
              <span class="font-semibold">"{{ change.oldValue }}"</span> to
              <span class="font-semibold">"{{ change.newValue }}"</span>
            </div>

            <div v-else>
              Assigned
              <span class="font-semibold">"{{ change.newValue }}"</span> as
              {{ change.column }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <div v-else class="no-history">No data found</div>
  </div>
</template>

<script>
export default {
  name: "ItemHistory",

  props: {
    history: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      changes: [],
    };
  },

  watch: {
    history: {
      immediate: true,
      deep: true,
      handler() {
        this.changes = this.history.map((item) => ({
          id: this.$nano.id(),
          changedBy: "admin@ezofis.com",
          changedAt: this.$day.format(item.key),
          changes: item.changes,
        }));
      },
    },
  },
};
</script>

<style lang="scss" scoped>
#history {
  padding: 16px;

  .no-history {
    font-weight: 500;
    padding: 16px 0px;
    text-align: center;
  }

  .entry {
    padding-left: 24px;
    padding-bottom: 24px;
    position: relative;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0px;
      top: 6px;
      background-color: var(--secondary);
      border-radius: 100%;
    }

    &:not(:last-child):after {
      content: "";
      width: 2px;
      height: calc(100% - 6px);
      position: absolute;
      left: 3px;
      top: 14px;
      background-color: var(--hover-bg-color);
    }

    .header {
      color: var(--title-1-color);
      font-weight: 600;
      margin-bottom: 4px;
    }

    .content {
      .changes-meta {
        display: flex;
        align-items: center;
        @extend .text-sm;
        color: var(--icon-color);
        margin-bottom: 16px;
      }

      .change {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-left: -6px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
