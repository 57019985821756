<template>
  <Sheet :value="value" position="left" width="360px" @input="closeSheet">
    <template #title> Files </template>

    <div id="file-sheet">
      <FileListItem
        v-for="file in fileList"
        :key="file.id"
        :file="file"
        mini-mode
        :is-selected="selectedFiles.id === file.id"
        class="q-pb-sm"
        @click="$emit('select', file.id)"
      />
    </div>
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import FileListItem from "./FileListItem.vue";

export default {
  name: "FileSheet",

  components: { Sheet, FileListItem },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    fileList: {
      type: Array,
      required: true,
    },

    selectedFiles: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isContentMaximized: true,
    };
  },

  computed: {
    _styles() {
      return {
        minHeight: "0px",
        height: "0px",
        padding: "0px",
        overflow: "hidden",
      };
    },
  },

  methods: {
    closeSheet() {
      this.$emit("input", false);
    },

    toggleContent() {
      this.isContentMaximized = !this.isContentMaximized;
    },
  },
};
</script>

<style lang="scss" scoped>
#file-sheet {
  .header {
    display: flex;
    align-items: center;
    padding: 16px;

    &:hover {
      cursor: pointer;
    }

    .label {
      flex: 1;
      color: var(--icon-color);
      font-weight: 500;
      font-size: 13px;
      text-transform: capitalize;
    }

    .icon {
      color: var(--icon-color-inverted);
      transition: all 0.3s ease;
    }
  }

  .no-files {
    color: var(--icon-color);
    text-align: center;
    padding: 0px 16px 16px;
  }
}
</style>
