<template>
  <Sheet :value="value" :width="width" @input="$emit('input', false)">
    <template #title>
      <slot name="title"></slot>
    </template>

    <template #actions>
      <BaseActionButton
        is-flat
        icon="eva-edit-outline"
        class="q-mr-sm"
        @click="$emit('edit')"
      />

      <BaseActionButton
        is-flat
        icon="eva-trash-2-outline"
        class="q-mr-sm"
        @click="$emit('delete')"
      />
    </template>

    <template #default>
      <div id="item-details">
        <!-- tabs -->

        <Tabs v-model="tab" :tabs="tabs" class="tabs" />

        <!-- ... -->

        <!-- overview -->

        <ItemOverview v-if="tab === 'overview'" :overview="overview">
          <template v-for="info in overview" #[info.name]>
            <slot :name="info.name" :value="info.value"></slot>
          </template>
        </ItemOverview>

        <!-- ... -->

        <!-- history -->

        <ItemHistory v-if="tab === 'history'" :history="history" />

        <!-- ... -->
      </div>

      <!-- ... -->
    </template>
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import ItemOverview from "./components/ItemOverview.vue";
import ItemHistory from "./components/ItemHistory.vue";

export default {
  name: "ItemDetails",

  components: { Sheet, Tabs, ItemOverview, ItemHistory },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    overview: {
      type: Array,
      required: true,
    },

    history: {
      type: Array,
      required: true,
    },

    width: {
      type: String,
      default: "540px",
    },
  },

  data() {
    return {
      tab: "overview",
      tabs: [
        {
          id: this.$nano.id(),
          label: "overview",
          value: "overview",
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "history",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#item-details {
  .tabs {
    padding: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
  }
}
</style>
