const Condition = {
  CONTAINS: "CONTAINS",
  IS_EQUALS_TO: "IS_EQUALS_TO (=)",
  IS_NOT_EQUALS_TO: "IS_NOT_EQUALS_TO (!=)",
  IS_GREATER_THAN: "IS_GREATER_THAN (>)",
  IS_GREATER_THAN_OR_EQUALS_TO: "IS_GREATER_THAN_OR_EQUALS_TO (>=)",
  IS_LESSER_THAN: "IS_LESSER_THAN (<)",
  IS_LESSER_THAN_OR_EQUALS_TO: "IS_LESSER_THAN_OR_EQUALS_TO (<=)",
  IS_EMPTY: "IS_EMPTY ({})",
  IS_NOT_EMPTY: "IS_NOT_EMPTY (!{})",
  IS_ANY_OF: "IS_ANY_OF",
  IS_NOT_ANY_OF: "IS_NOT_ANY_OF",
  NOT_CONTAINS: "NOT_CONTAINS",
  STARTS_WITH: "STARTS_WITH",
  NOT_STARTS_WITH: "NOT_STARTS_WITH",
  ENDS_WITH: "ENDS_WITH",
  NOT_ENDS_WITH: "NOT_ENDS_WITH",
};

Object.freeze(Condition);

export default Condition;
