<template>
  <div id="toggle">
    <div
      v-for="option in options"
      :key="option"
      class="option"
      :class="{ 'is-selected': value === option }"
      @click="$emit('input', option)"
    >
      {{ option }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Toggle",

  props: {
    value: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#toggle {
  display: flex;
  align-items: center;
  background-color: var(--body-bg-color);
  padding: 4px;
  border-radius: 36px;
  transition: background-color 0.25s ease;

  .option {
    @extend .text-sm;
    padding: 8px 16px;

    &:hover {
      cursor: pointer;
      color: var(--secondary);
    }

    &.is-selected {
      background-color: var(--component-bg-color);
      color: var(--secondary);
      font-weight: 600;
      border-radius: 36px;
    }
  }
}
</style>
