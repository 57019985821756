<template>
  <div id="entity-thumbnail">
    <BaseScrollbar height="calc(100vh - 300px)">
      <div v-for="{ key, value, data } in files" :key="value">
        <div v-if="key" class="group-by">
          <span class="key">{{ key }}: </span>
          <span>{{ value }} </span>
          <span>({{ data.length }})</span>
        </div>

        <div class="row q-col-gutter-md">
          <div v-for="file in data" :key="file.id" class="col-md-3 col-lg-2">
            <div class="file" @click="handleOpenFile(file.id, file)">
              <div class="thumbnail">
                <img :src="showThumbnail(file)" alt="thumnail" />
              </div>

              <div class="col row items-center q-mt-md">
                <div class="col q-mr-sm">
                  <div class="name">{{ file.name }}</div>
                  <div class="stats">
                    <div class="stat">{{ file.createdBy }}</div>
                    <BaseIcon
                      name="mdi-circle-small"
                      inherit-color
                      class="icon"
                    />
                    <div class="stat">{{ file.size }}</div>
                  </div>
                </div>

                <!-- <BaseIcon name="eva-more-vertical" /> -->
                <BaseIcon
                  v-if="file.isPassword"
                  name="eva-lock"
                  color="red"
                  class="icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
export default {
  name: "EntityThumbnail",

  props: {
    columns: {
      type: Array,
      required: true,
    },

    files: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },

    repositoryId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedFile: {},
      showFileSheet: false,
    };
  },

  methods: {
    handleSelect(entityId) {
      this.$emit("select", entityId);
    },

    handleDelete(entityId) {
      this.$emit("delete", entityId);
    },

    handleRestore(entityId) {
      this.$emit("restore", entityId);
    },

    handleMore(entityId) {
      this.$emit("more", entityId);
    },

    showThumbnail(file) {
      return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.repositoryId}/${file.id}/1`;
    },

    handleOpenFile(itemId, item) {
      if (item) {
        // console.log(item);

        // this.selectedFile = {};
        // this.showFileSheet = true;
        // this.selectedFile = {
        //   name: item.name,
        //   size: item.size,
        //   createdAt: item.createdAt,
        //   createdBy: item.createdBy,
        //   id: item.id,
        //   type: item.name.split(".")[1],
        //   isPassword: item.isPassword,
        //   passwordCreatedBy: item.passwordCreatedBy,
        // };
        // this.$emit("minimumFileSheet", this.selectedFile);
        this.$emit("view", itemId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  padding: 16px;
  height: 100%;
  position: relative;
  cursor: pointer;

  .thumbnail {
    background-color: var(--body-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 4px;

    img {
      height: 200px;
      width: 100%;
    }
  }

  .name {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .icon {
    color: var(--icon-color-inverted);
  }

  .stats {
    display: flex;
    align-items: center;

    .stat {
      @extend .text-sm;
      color: var(--icon-color);
    }
  }
}
</style>
