import Condition from "../constants/condition.js";
import DataType from "../constants/data-type.js";

export default function conditions(dataType) {
  switch (dataType) {
    case DataType.NUMBER:
    case DataType.DATE:
    case DataType.TIME:
    case DataType.DATE_TIME:
    case DataType.COUNTER:
    case DataType.CALCULATED:
      return [
        Condition.IS_EQUALS_TO,
        Condition.IS_NOT_EQUALS_TO,
        Condition.IS_GREATER_THAN,
        Condition.IS_GREATER_THAN_OR_EQUALS_TO,
        Condition.IS_LESSER_THAN,
        Condition.IS_LESSER_THAN_OR_EQUALS_TO,
        Condition.IS_EMPTY,
        Condition.IS_NOT_EMPTY,
        Condition.IS_ANY_OF,
        Condition.IS_NOT_ANY_OF,
        Condition.STARTS_WITH,
        Condition.NOT_STARTS_WITH,
        Condition.ENDS_WITH,
        Condition.NOT_ENDS_WITH,
      ];
    case DataType.SHORT_TEXT:
    case DataType.LONG_TEXT:
    case DataType.TABLE:
      return [
        Condition.CONTAINS,
        Condition.IS_EQUALS_TO,
        Condition.IS_NOT_EQUALS_TO,
        Condition.IS_EMPTY,
        Condition.IS_NOT_EMPTY,
        Condition.IS_ANY_OF,
        Condition.IS_NOT_ANY_OF,
        Condition.NOT_CONTAINS,
        Condition.STARTS_WITH,
        Condition.NOT_STARTS_WITH,
        Condition.ENDS_WITH,
        Condition.NOT_ENDS_WITH,
      ];
    case DataType.SINGLE_SELECT:
    case DataType.MULTI_SELECT:
    case DataType.MULTIPLE_CHOICE:
      return [
        Condition.CONTAINS,
        Condition.IS_EMPTY,
        Condition.IS_NOT_EMPTY,
        Condition.IS_EQUALS_TO,
        Condition.IS_NOT_EQUALS_TO,
        Condition.IS_ANY_OF,
        Condition.IS_NOT_ANY_OF,
      ];
    default:
      return [
        Condition.IS_EMPTY,
        Condition.IS_NOT_EMPTY,
        Condition.IS_EQUALS_TO,
        Condition.IS_NOT_EQUALS_TO,
      ];
  }
}
