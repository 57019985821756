<template>
  <Confirm
    :value="value"
    icon="eva-trash-2-outline"
    icon-color="red"
    @input="handleInput"
  >
    <template #title>Delete Repository?</template>

    <template #default>
      <div class="q-py-md">
        <p>
          Are you sure you want to delete the repository
          <b>"{{ repository.name }}"</b>. If you delete the repository, you will
          not be able to use the repository.
        </p>

        <p>
          You can always restore the deleted repository from the trash page.
        </p>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="red"
        @click="handleNo"
      />
      <BaseButton label="yes" color="red" @click="handleYes" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmDeleteRepository",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    repository: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("delete", { isDeleted: true });
    },
  },
};
</script>

<style lang="scss" scoped></style>
