<template>
  <div id="item-list">
    <slot v-if="selectedDummyData"></slot>
    <BaseScrollbar
      :height="
        breadcrumbs || report ? 'calc(100vh - 310px)' : 'calc(100vh - 260px)'
      "
    >
      <table>
        <thead v-if="tableHeader">
          <tr style="background-color: var(--body-bg-color)">
            <!-- avatar -->

            <th class="sticky icon" style="left: 0">
              <div v-if="checkbox" class="row items-center justify-center">
                <CheckboxField
                  v-model="selectAll"
                  class="col-auto q-pt-sm q-pb-sm"
                  @input="handleSelectAll"
                />
              </div>
            </th>

            <!-- ... -->

            <!-- title -->

            <template v-if="repository && checkbox">
              <th class="sticky file-mark-icon" style="left: 44px"></th>

              <th v-if="title" class="sticky" style="left: 80px">
                <slot name="titleHead" :title="title.label">
                  {{ title.label }}
                </slot>
              </th>
            </template>

            <template v-else>
              <th v-if="title" class="sticky" style="left: 36px">
                <slot name="titleHead" :title="title.label">
                  {{ title.label }}
                </slot>
              </th>
            </template>

            <!-- ... -->

            <!-- columns -->

            <th v-for="column in _columns" :key="column.id">
              {{ column.label }}
            </th>

            <!-- ... -->

            <!-- actions -->

            <th v-if="!noActions" class="sticky action" style="right: 0"></th>

            <!-- ... -->
          </tr>
        </thead>

        <tbody>
          <template v-for="{ key, value, data } in items">
            <tr v-if="key" :key="value">
              <td class="group-by" colspan="2">
                <span class="key">{{ key }}: </span>
                <span>{{ value }} </span>
                <span>({{ data.length }})</span>
              </td>
            </tr>
            <template v-for="(item, idx) in data">
              <tr
                :key="item.id"
                :class="{
                  'no-border': idx === 0,
                  background: !noBackground,
                  checkout: item.checkinStatus === 2,
                }"
                @click="toggleLink(item, data)"
              >
                <!-- avatar -->

                <template v-if="repository && checkbox">
                  <td class="sticky icon" style="left: 0">
                    <div
                      v-if="checkbox"
                      class="row items-center justify-center"
                    >
                      <CheckboxField
                        v-model="item.selected"
                        @input="handleCheckboxChange(item.id)"
                      />
                    </div>
                  </td>

                  <td class="sticky file-mark-icon" style="left: 44px">
                    <div
                      class="row items-center justify-center"
                      style="width: 20px"
                    >
                      <slot name="avatar" :item="item"> </slot>
                    </div>
                  </td>
                </template>

                <template v-else>
                  <td class="sticky icon" style="left: 0; width: 36px">
                    <div
                      v-if="checkbox"
                      class="col items-center justify-center"
                    >
                      <CheckboxField
                        v-model="item.selected"
                        @input="handleCheckboxChange(item.id)"
                      />
                      <template v-if="item.subWorkflow">
                        <BaseIcon
                          v-if="item.subWorkflow.length"
                          :name="
                            item.expandSubworkflow
                              ? 'mdi-chevron-down'
                              : 'mdi-chevron-up'
                          "
                          class="cursor-pointer q-ml-sm"
                        />
                      </template>
                    </div>
                    <div v-else class="col items-center justify-center">
                      <slot name="avatar" :item="item">
                        <template v-if="item.buttonAction">
                          <template v-if="item.status !== 'Closed'">
                            <BaseIcon
                              v-tooltip.top="'Mark Completed'"
                              :name="
                                selectedRowIndex === idx
                                  ? 'mdi-check'
                                  : item.icon
                              "
                              :color="
                                selectedRowIndex === idx
                                  ? 'primary'
                                  : item.color
                              "
                              @mouseover="selectedRowIndex = idx"
                              @mouseout="selectedRowIndex = -1"
                              @click="$emit('complete', item.id)"
                            />
                          </template>
                          <template v-else>
                            <BaseIcon :name="item.icon" :color="item.color" />
                          </template>
                        </template>
                        <template v-else>
                          <template v-if="item.iconTooltip">
                            <BaseIcon
                              v-tooltip.top="item.iconTooltip"
                              :name="item.icon"
                              :color="item.color || 'secondary'"
                            />
                          </template>
                          <template v-else>
                            <BaseIcon
                              :name="item.icon"
                              :color="item.color || 'secondary'"
                            />
                          </template>
                        </template>
                      </slot>
                      <template v-if="item.subWorkflow">
                        <BaseIcon
                          v-if="item.subWorkflow.length"
                          :name="
                            item.expandSubworkflow
                              ? 'mdi-chevron-down'
                              : 'mdi-chevron-up'
                          "
                          class="cursor-pointer q-ml-sm"
                        />
                      </template>
                    </div>
                  </td>
                </template>

                <!-- ... -->

                <!-- title -->

                <td
                  v-if="title"
                  class="sticky"
                  :style="'left:' + leftcount"
                  @click="item.link ? $emit('select', item.id, data) : ''"
                >
                  <slot name="title" :item="item" :title="item[title.name]">
                    <div
                      class="font-medium ellipsis"
                      :class="item.link ? 'text-underline' : ''"
                      style="max-width: 250px"
                    >
                      <span
                        @mouseover="$emit('showThumbnail', item, $event)"
                        @mouseleave="$emit('hideThumbnail', item)"
                        >{{ item[title.name] }}
                      </span>
                    </div>
                  </slot>
                  <slot name="thumbnail" :item="item"></slot>
                </td>

                <!-- ... -->

                <!-- columns -->

                <td v-for="column in _columns" :key="column.id">
                  <slot
                    :name="column.name"
                    :value="item[column.name]"
                    :item="item"
                  >
                    <template v-if="column.dataType === 'TABLE'">
                      <template v-if="item[column.name]">
                        <BaseActionButton
                          v-tooltip.top="'Table Data'"
                          icon="mdi-table"
                          is-flat
                          no-border
                          @click.stop="
                            showTable(column.name, item[column.name])
                          "
                        />
                      </template>
                      <template v-else>
                        <BaseActionButton
                          icon="mdi-table"
                          is-flat
                          no-border
                          is-disabled
                        />
                      </template>
                    </template>
                    <template v-else-if="column.type === 'ICON'">
                      <template v-if="item[column.name]">
                        <BaseIcon
                          v-tooltip.top="item.iconTooltip[column.name]"
                          :name="item[column.name]"
                          :color="item.iconColor[column.name]"
                        />
                      </template>
                      <template v-else>
                        {{ "-" }}
                      </template>
                    </template>
                    <template v-else-if="column.dataType === 'FILE_UPLOAD'">
                      <template v-if="item[column.name]">
                        <div
                          v-if="typeof item[column.name] === 'string'"
                          class="ellipsis medium"
                        >
                          <div
                            v-for="(file, index) in JSON.parse(
                              item[column.name]
                            )"
                            :key="index"
                            class="row"
                          >
                            <FileIcon
                              :mime-type="fileType(file.fileName)"
                              class="mini-avatar col-auto q-mr-sm"
                            />
                            <span
                              class="text-secondary file-underline col"
                              @click="viewFile(file, item)"
                              >{{ file.fileName }}</span
                            >
                          </div>
                        </div>
                        <div v-else class="ellipsis medium">
                          <div
                            v-for="(file, index) in item[column.name]"
                            :key="index"
                            class="row"
                          >
                            <FileIcon
                              :mime-type="fileType(file.fileName)"
                              class="mini-avatar col-auto q-mr-sm"
                            />
                            <span
                              class="text-secondary file-underline col"
                              @click="viewFile(file, item)"
                              >{{ file.fileName }}</span
                            >
                          </div>
                        </div>
                      </template>
                    </template>

                    <template v-else>
                      <div
                        :class="{ 'data-change': hasMetaDataEdit }"
                        @click="handleOpenInput(item, column)"
                      >
                        {{ item[column.name] || "-" }}
                      </div>
                    </template>
                  </slot>
                </td>

                <!-- ... -->

                <!-- actions -->

                <td
                  v-if="!noActions"
                  class="sticky action"
                  style="right: 0; width: 50px"
                >
                  <slot name="actions" :item="item">
                    <template v-if="mode === 'BROWSE'">
                      <template v-if="!item.actionAccess">
                        <div class="row justify-end no-wrap">
                          <template v-if="security || visibility(item)">
                            <template v-if="item.actionButton">
                              <!-- info -->
                              <BaseActionButton
                                v-if="item.infoIcon"
                                v-tooltip.top="item.infoIconTooltip"
                                :icon="item.infoIcon"
                                :color="
                                  item.infoIconColor
                                    ? item.infoIconColor
                                    : 'primary'
                                "
                                is-flat
                                no-border
                                @click.stop="$emit('info', item.id)"
                              />
                              <!-- ... -->

                              <!-- edit -->

                              <BaseActionButton
                                is-flat
                                no-border
                                icon="mdi-check-all"
                                color="secondary"
                                @click.stop="$emit('edit', item.id)"
                              />

                              <BaseActionButton
                                is-flat
                                no-border
                                icon="mdi-bell-outline"
                                color="secondary"
                                @click.stop="$emit('edit', item.id)"
                              />

                              <BaseActionButton
                                is-flat
                                no-border
                                icon="eva-edit-outline"
                                color="secondary"
                                @click.stop="$emit('edit', item.id)"
                              />

                              <!-- ... -->

                              <!-- security -->

                              <BaseActionButton
                                is-flat
                                no-border
                                icon="eva-shield-outline"
                                @click.stop="$emit('secure', item.id)"
                              />

                              <!-- ... -->

                              <!-- delete -->

                              <BaseActionButton
                                is-flat
                                no-border
                                icon="eva-trash-2-outline"
                                color="red"
                                @click.stop="$emit('delete', item.id)"
                              />

                              <!-- ... -->

                              <!-- more -->

                              <BaseActionButton
                                is-flat
                                no-border
                                icon="eva-more-vertical"
                                @click.stop="$emit('more', item.id)"
                              >
                                <slot name="extraMenu"></slot>
                              </BaseActionButton>

                              <!-- ... -->
                            </template>
                            <template v-else>
                              <!-- info -->
                              <BaseActionButton
                                v-if="item.infoIcon"
                                v-tooltip.top="item.infoIconTooltip"
                                :icon="item.infoIcon"
                                :color="
                                  item.infoIconColor
                                    ? item.infoIconColor
                                    : 'primary'
                                "
                                is-flat
                                no-border
                                @click.stop="$emit('info', item.id)"
                              />
                              <!-- ... -->

                              <BaseActionButton
                                v-if="item.emailStatus === 1"
                                v-tooltip.top="'send invite'"
                                is-flat
                                no-border
                                icon="mdi-email-check-outline"
                                color="green"
                                @click.stop="$emit('resend', item.id)"
                              />
                              <BaseActionButton
                                v-else-if="item.emailStatus === 0"
                                v-tooltip.top="'send invite'"
                                is-flat
                                no-border
                                icon="mdi-email-sync-outline"
                                color="orange"
                                @click.stop="$emit('resend', item.id)"
                              />

                              <BaseActionButton
                                v-else-if="resend"
                                v-tooltip.top="'send invite'"
                                is-flat
                                no-border
                                icon="mdi-email-outline"
                                color="secondary"
                                @click.stop="$emit('resend', item.id)"
                              />

                              <!-- edit -->

                              <BaseActionButton
                                is-flat
                                no-border
                                icon="eva-edit-outline"
                                color="secondary"
                                @click.stop="$emit('edit', item.id)"
                              />

                              <!-- ... -->

                              <!-- delete -->

                              <BaseActionButton
                                is-flat
                                no-border
                                icon="eva-trash-2-outline"
                                color="red"
                                @click.stop="$emit('delete', item.id)"
                              />

                              <!-- ... -->

                              <!-- export -->

                              <BaseActionButton
                                v-if="showExport"
                                is-flat
                                no-border
                                icon="eva-download-outline"
                                color="orange"
                                @click.stop="$emit('export', item)"
                              />

                              <!-- ... -->

                              <!-- more -->

                              <BaseActionButton
                                is-flat
                                no-border
                                icon="eva-more-vertical"
                                @click.stop="$emit('more', item.id)"
                              >
                                <slot name="extraMenu"></slot>
                              </BaseActionButton>

                              <!-- ... -->
                            </template>
                          </template>
                        </div>
                      </template>
                    </template>
                    <!-- <template v-else>
                    <div class="row justify-center no-wrap">
                      <BaseActionButton
                        is-flat
                        no-border
                        icon="eva-eye-outline"
                        color="secondary"
                        @click.stop="$emit('select', item.id)"
                      />
                    </div>
                  </template> -->

                    <template v-if="mode === 'TRASH'">
                      <!-- restore -->

                      <div class="row justify-end">
                        <BaseActionButton
                          is-flat
                          color="secondary"
                          icon="mdi-history"
                          no-border
                          @click.stop="$emit('restore', item.id)"
                        />
                      </div>

                      <!-- ... -->
                    </template>
                  </slot>
                </td>

                <!-- ... -->
              </tr>
              <template v-if="item.subWorkflow">
                <template v-if="item.subWorkflow.length">
                  <tr
                    v-if="item.expandSubworkflow"
                    :key="item.id"
                    class="sub-background"
                  >
                    <td :colspan="_columns.length + 3">
                      <table
                        :key="`subtable${item.id}`"
                        class="subWorkflowTable"
                        style="border: 1px solid var(--divider-color)"
                      >
                        <thead>
                          <tr>
                            <th
                              class="sticky icon"
                              name="icon"
                              style="
                                left: 0;
                                background-color: rgb(222 217 247);
                                width: 2%;
                              "
                            ></th>

                            <th
                              v-if="title"
                              class="sticky"
                              name="title"
                              style="
                                left: 36px;
                                background-color: rgb(222 217 247);
                                width: 2%;
                              "
                            >
                              <div
                                class="font-medium ellipsis"
                                style="max-width: 250px"
                              >
                                {{ title.label }}
                              </div>
                            </th>

                            <th
                              v-for="column in _subWorkflowColumns"
                              :key="column.id"
                              style="
                                background-color: rgb(222 217 247);
                                width: 2%;
                              "
                            >
                              {{ column.label }}
                            </th>
                            <th
                              class="sticky"
                              style="background-color: rgb(222 217 247)"
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="subItem in item.subWorkflow">
                            <tr :key="subItem.requestNo">
                              <td
                                class="sticky icon sub-background"
                                name="avatar"
                                style="left: 0"
                              >
                                <div
                                  class="row items-center sticky justify-center"
                                >
                                  <BaseIcon
                                    v-tooltip.top="subItem.iconTooltip"
                                    :name="subItem.icon"
                                    :color="subItem.color"
                                  />
                                </div>
                              </td>

                              <td
                                class="sticky sub-background"
                                name="title"
                                style="
                                  left: 0;
                                  width: 20%;
                                  color: #007bff;
                                  cursor: pointer;
                                "
                              >
                                <div
                                  v-tooltip.top="subItem.requestNo"
                                  style="
                                    overflow: hidden !important;
                                    white-space: unset !important;
                                    width: 220px;
                                  "
                                  class="row items-center text-underline"
                                  @click="openSubProcess(subItem)"
                                >
                                  {{ subItem.requestNo || "-" }}
                                </div>
                              </td>
                              <td style="width: 20%" class="sub-background">
                                {{ subItem.stage }}
                              </td>
                              <td style="width: 20%" class="sub-background">
                                {{ subItem.raisedBy }}
                              </td>
                              <td style="width: 20%" class="sub-background">
                                {{ subItem.raisedAt }}
                              </td>

                              <td
                                class="sticky sub-background"
                                style="right: 0px; width: 20%"
                              >
                                <slot name="subactions" :item="subItem"> </slot>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </BaseScrollbar>

    <Modal
      v-model="expandTableModal"
      width="60vw"
      height="80vh"
      @input="expandTableModal = false"
    >
      <!-- title -->

      <template #title>{{ tableTitle }}</template>

      <!-- ... -->

      <template #default>
        <div id="tableData">
          <template v-if="tableData.cellvalues">
            <table>
              <tr v-for="(row, idx) in tableData.cellvalues" :key="idx">
                <th
                  :is="cell.kind === 'NoHeader' ? 'td' : 'th'"
                  v-for="cell in row"
                  :key="`r${idx}_c${cell.columnindex}`"
                >
                  {{ cell.cellvalue }}
                </th>
              </tr>
            </table>
          </template>
          <template v-else>
            <table>
              <thead>
                <tr>
                  <th v-for="(value, key) in tableData[0]" :key="key">
                    {{ key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in tableData" :key="index">
                  <td v-for="(value, key) in row" :key="key">
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import FileIcon from "@/components/common/FileIcon.vue";

export default {
  name: "ItemList",

  components: {
    CheckboxField,
    Modal,
    FileIcon,
  },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },

    noBackground: {
      type: Boolean,
      default: false,
    },

    noActions: {
      type: Boolean,
      default: false,
    },

    tableHeader: {
      type: Boolean,
      default: true,
    },

    security: {
      type: Boolean,
      default: true,
    },

    checkbox: {
      type: Boolean,
      default: false,
    },

    hasMetaDataEdit: {
      type: Boolean,
      required: false,
    },

    breadcrumbs: {
      type: Boolean,
      default: false,
    },

    report: {
      type: Boolean,
      default: false,
    },

    resend: {
      type: Boolean,
      default: false,
    },

    selectAll: {
      type: Boolean,
      default: false,
    },

    repository: {
      type: Boolean,
      default: false,
    },

    showExport: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedRowIndex: -1,
      userId: this.$store.state.session.id,
      email: this.$store.state.session.email,
      selectedDummyData: false,
      expandTableModal: false,
      tableTitle: "",
      tableData: [],
    };
  },

  computed: {
    _columns() {
      return this.columns.filter(
        (column) => column.type !== "TITLE" && column.isVisible
      );
    },

    title() {
      return this.columns.find((column) => column.type === "TITLE");
    },

    leftcount() {
      if (this.repository && this.checkbox) {
        return "80px";
      } else {
        return "36px";
      }
    },

    _subWorkflowColumns() {
      let tableColumns = [
        {
          id: this.$nano.id(),
          name: "stage",
          label: "Stage",
          type: "SINGLE_SELECT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedBy",
          label: "Raised By",
          type: "SINGLE_SELECT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedAt",
          label: "Raised On",
          type: "DATE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
      ];
      return tableColumns;
    },
  },

  created() {
    this.items.forEach((group) => {
      group.data.forEach((item) => {
        this.$set(item, "selected", false);
      });
    });
  },

  methods: {
    visibility(item) {
      const userId = Number(this.userId);
      const email = this.email;
      let hasMatch = false;
      const ownerIds = item.owner ? item.owner.map((owner) => owner.id) : [0];
      const coordinatorIds = item.coordinator
        ? item.coordinator.map((coordinator) => coordinator.id)
        : [0];
      if (
        item.createdBy === email ||
        ownerIds.includes(userId) ||
        coordinatorIds.includes(userId) ||
        userId === 1
      ) {
        hasMatch = true;
      }
      return hasMatch;
    },

    handleCheckboxChange(id) {
      this.selectAll = false;
      this.selectedDummyData = !this.selectedDummyData;
      const itemToUpdate = this.items
        .flatMap((group) => group.data)
        .find((item) => item.id === id);

      if (itemToUpdate) {
        this.$set(itemToUpdate, "selected", itemToUpdate.selected);
      }
      this.$emit("update-selected-all", this.selectAll);
    },

    handleSelectAll() {
      if (this.selectAll === true) {
        this.items.forEach((group) => {
          group.data.forEach((item) => {
            this.$set(item, "selected", true);
          });
        });
      }
      if (this.selectAll === false) {
        this.items.forEach((group) => {
          group.data.forEach((item) => {
            this.$set(item, "selected", false);
          });
        });
      }
      this.$emit("update-selected-all", this.selectAll);
    },

    showTable(name, value) {
      if (value) {
        if (this.report) {
          this.$emit("showTable", name, value);
        } else {
          this.tableTitle = name;
          this.tableData = JSON.parse(value);
          this.expandTableModal = true;
        }
      }
    },

    handleOpenInput(data, column) {
      this.$emit("openMetaDataEdit", data, column);
    },

    viewFile(file, item) {
      file.createdBy = item.createdBy;
      file.createdAt = item.createdAt;
      if (!file.name) {
        file.name = file.fileName;
      }
      if (!file.id) {
        file.id = file.itemId;
      }
      this.$emit("viewFile", file);
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    toggleLink(item, data) {
      item.expandSubworkflow = !item.expandSubworkflow;
      item.link ? "" : this.$emit("select", item.id, data);
      this.selectedDummyData = !this.selectedDummyData;
    },

    openSubProcess(item) {
      this.$emit("openSubWorkflow", item);
    },
  },
};
</script>

<style lang="scss" scoped>
#item-list {
  .icon-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.5rem;
    outline: none;
  }

  table {
    table-layout: fixed;
    max-width: 100%;
    &:not(.subWorkflowTable) {
      min-width: calc(100% - 1px);
    }
    border-collapse: collapse;
    white-space: nowrap;

    thead {
      tr {
        height: 36px;

        th {
          @extend .text-xs;
          padding: 8px 8px 16px;
          color: var(--icon-color-inverted);
          text-align: left;
          font-weight: normal;

          &.sticky {
            background-color: var(--body-bg-color);
            position: sticky;
          }
        }
      }
    }

    tbody {
      tr {
        border-top: 1px solid var(--divider-color);

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          border-top: none;
        }

        td {
          padding: 8px;
          height: 52px;
          background-color: var(--body-bg-color);

          &.sticky {
            // background-color: var(--component-bg-color);
            position: sticky;
            z-index: 1;
          }

          &.group-by {
            color: var(--deep-orange);
            font-weight: 500;
            background-color: transparent;
            // padding: 36px 0px 16px;
            padding: 0px;
            position: sticky;
            left: 0;
            z-index: 1;

            &:first-child {
              padding-top: 0px;
            }

            .key {
              color: var(--icon-color);
              text-transform: capitalize;
            }
          }

          :deep .stat {
            text-decoration: underline;

            &:hover {
              color: var(--secondary);
            }
          }
        }

        &.background td {
          background-color: var(--component-bg-color);
        }
      }
    }

    .mini-avatar {
      width: 18px;
      height: 18px;
    }
  }

  #checkbox-field {
    padding: 0px !important;
  }

  .text-underline {
    text-decoration: underline;
  }

  .ellipsis:hover {
    max-width: none !important;
    overflow: visible;
    white-space: pre;
  }
}

#modal #tableData {
  table {
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid var(--divider-color);
    border-radius: 10px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th {
      border-bottom: 1px solid var(--divider-color);
    }

    tr:not(:first-child) td {
      border-top: 1px solid var(--divider-color); /* Add top border to the first row */
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color); /* Add top border to the first row */
    }
  }
}

.checkout {
  border-left: 2px solid red !important;
}

.data-change:hover {
  cursor: auto;
}

.file-underline {
  text-decoration: underline;
}

.sub-background {
  background-color: white !important;
}
</style>
